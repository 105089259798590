import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  FormControlLabel,
  makeStyles,
  Switch,
} from '@material-ui/core'
import { toggleDarkMode } from './reducers/darkMode'

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 900,
    lineHeight: '14px',
    textTransform: 'uppercase',
  }
}))
function ThemeSwitch() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const darkMode = useSelector<{
    darkMode: boolean
  }, boolean>(state => state.darkMode)
  const handleOnToggle = () => {
    dispatch(toggleDarkMode())
  }
  return (
    <FormControlLabel
      classes={{
        label: classes.label
      }}
      control={
        <Switch
          checked={darkMode}
          color="primary"
          onChange={handleOnToggle}
          name="theme"
          inputProps={{ 'aria-label': 'theme checkbox' }}
        />
      }
      label={darkMode ? 'Dark' : 'Light'}
      labelPlacement='start'
    />
  )
}

export default ThemeSwitch
