import React from "react";
import AdyenCheckout from "@adyen/adyen-web";
import "@adyen/adyen-web/dist/adyen.css";
import { IAdyenPaymentData, IPayload } from './DonatePage';
import { Box, useTheme } from "@material-ui/core";
import { Amex, Mastercard, Visa } from "./cards";

export function Payment({ setPayload }: {
  setPayload: React.Dispatch<React.SetStateAction<IPayload<IAdyenPaymentData>>>
}) {
  const checkout = React.useRef<AdyenCheckout>()
  const theme = useTheme()
  const [isValid, setIsValid] = React.useState(false)

  React.useEffect(() => {
    console.log('isValid', isValid)
    if (!isValid) {
      setPayload(prev => ({
        ...prev,
        paymentData: undefined,
      }))
    }
  }, [isValid, setPayload])

  React.useEffect(() => {
    console.log('update checkout', theme.palette)
    if (checkout.current) {
      checkout.current.update({
        styles: {
          base: {
            color: theme.palette.text.primary,
          },
          error: {
            color: 'red'
          },
          validated: {
            color: 'green'
          },
          placeholder: {
            color: '#d8d8d8'
          }
        },
      })
    }
    else {
      checkout.current = new AdyenCheckout({
        locale: "en_US",
        environment: process.env.REACT_APP_ADYEN_ENV,
        clientKey: process.env.REACT_APP_ADYEN_CLIENT_KEY,
      });

      checkout.current.create('securedfields', {
        type: 'card',
        brands: ['amex', 'mc', 'visa'],
        styles: {
          base: {
            backgroundColor: 'transparent',
            color: theme.palette.text.primary + ' !important',
          },
          error: {
            color: 'red'
          },
          validated: {
            color: 'green'
          },
          placeholder: {
            color: '#d8d8d8'
          }
        },
        ariaLabels: {
          lang: 'en-GB',
          encryptedCardNumber: {
            label: 'Credit or debit card number field'
          }
        },
        // Events
        onChange: function (state: any, component: any) {
          console.log('onChange', state, component)
          if (!state.isValid) {
            setIsValid(false)
          }
        },
        onValid: function (e: any) {
          console.log('onValid', e)
          setPayload(prev => ({
            ...prev,
            paymentData: e.data
          }))
          setIsValid(true)
        },
        onLoad: function (e: any) {
          console.log('onLoad', e)
        },
        onConfigSuccess: function (e: any) {
          console.log('onConfigSuccess', e)
        },
        onFieldValid: function (e: any) {
          console.log('onFieldValid', e)
        },
        onBrand: function (e: any) {
          console.log('onBrand', e)
        },
        onError: function (e: any) {
          console.log('onError', e)
        },
        onFocus: function (e: any) {
          console.log('onFocus', e)
        },
        onBinValue: function (e: any) {
          console.log('onBinValue', e)
        },
      }).mount('#customCard-container');
    }
  }, [setIsValid, setPayload, theme])

  const labelStyle = {
    color: theme.palette.text.primary
  }
  const inputStyle = {
    backgroundColor: theme.palette.background.default
  }
  
  return (
    <>
      <Box display='flex' flexDirection='row' justifyContent='space-between' marginBottom='10px' width={180}>
        <Amex /><Mastercard /><Visa />
      </Box>
      <div id="customCard-container">
        <div className="adyen-checkout__field adyen-checkout__field--cardNumber">
          <label className="adyen-checkout__label">
            <span className="adyen-checkout__label__text" style={labelStyle}>Card number:</span>
            <div className="adyen-checkout__input-wrapper">
              <span
                className="adyen-checkout__input adyen-checkout__input--large adyen-checkout__card__cardNumber__input"
                data-cse="encryptedCardNumber"
                style={inputStyle}
              />
            </div>
          </label>
        </div>
        <div className="adyen-checkout__card__exp-cvc adyen-checkout__field-wrapper">
          <div className="adyen-checkout__field adyen-checkout__field--50 adyen-checkout__field--expiryDate">
            <label className="adyen-checkout__label">
              <span className="adyen-checkout__label__text" style={labelStyle}>Expiry date:</span>
              <div className="adyen-checkout__input-wrapper">
                <span
                  className="adyen-checkout__input adyen-checkout__input--small adyen-checkout__card__exp-date__input"
                  data-cse="encryptedExpiryDate"
                  style={inputStyle}
                />
              </div>
            </label>
          </div>
          <div className="adyen-checkout__field adyen-checkout__field--50 adyen-checkout__field__cvc adyen-checkout__field--securityCode">
            <label className="adyen-checkout__label">
              <span className="adyen-checkout__label__text" style={labelStyle}>CVV/CVC:</span>
              <div className="adyen-checkout__input-wrapper">
                <span
                  className="adyen-checkout__input adyen-checkout__input--small adyen-checkout__card__cvc__input"
                  data-cse="encryptedSecurityCode"
                  style={inputStyle}
                />
                <div className="adyen-checkout__card__cvc__hint__wrapper adyen-checkout__field__cvc--back-hint">
                  <svg className="adyen-checkout__card__cvc__hint adyen-checkout__card__cvc__hint--front" width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 3C0 1.34315 1.34315 0 3 0H24C25.6569 0 27 1.34315 27 3V15C27 16.6569 25.6569 18 24 18H3C1.34315 18 0 16.6569 0 15V3Z" fill="#E6E9EB"></path>
                    <rect x="4" y="12" width="19" height="2" fill="#B9C4C9"></rect>
                    <rect x="4" y="4" width="4" height="4" rx="1" fill="white"></rect>
                    <rect className="adyen-checkout__card__cvc__hint__location" x="16.5" y="4.5" width="7" height="5" rx="2.5" stroke="#D10244"></rect>
                  </svg>
                  <svg className="adyen-checkout__card__cvc__hint adyen-checkout__card__cvc__hint--back" width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M27 4.00001V3.37501C27 2.4799 26.6444 1.62146 26.0115 0.988518C25.3786 0.355581 24.5201 0 23.625 0H3.375C2.47989 0 1.62145 0.355581 0.988514 0.988518C0.355579 1.62146 0 2.4799 0 3.37501V4.00001H27Z" fill="#E6E9EB"></path>
                    <path d="M0 6.99994V14.6666C0 15.5507 0.355579 16.3985 0.988514 17.0237C1.62145 17.6488 2.47989 18 3.375 18H23.625C24.5201 18 25.3786 17.6488 26.0115 17.0237C26.6444 16.3985 27 15.5507 27 14.6666V6.99994H0Z" fill="#E6E9EB"></path>
                    <rect y="4.00012" width="27" height="3.00001" fill="#687282"></rect>
                    <path d="M4 11C4 10.4477 4.44772 10 5 10H21C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14H5C4.44771 14 4 13.5523 4 13V11Z" fill="white"></path>
                    <rect className="adyen-checkout__card__cvc__hint__location" x="16.5" y="9.5" width="7" height="5" rx="2.5" stroke="#D10244"></rect>
                  </svg>
                </div>
              </div>
            </label>
          </div>
        </div>
        <div style={{ marginTop: 16 }}>
          <label className='adyen-checkout__label'>
            <span className='adyen-checkout__label__text' style={labelStyle}>Name on card:</span>
          </label>
          <input
            className={'adyen-checkout__input'}
            type={'text'}
            style={{ ...inputStyle, ...labelStyle, fontSize: 16 }}
            name={'holderName'}
            id={'holderName'}
            placeholder={'J. Smith'}
            required
            onChange={e => setPayload(prev => (
              prev.paymentData?.paymentMethod
                ? {
                  ...prev,
                  paymentData: {
                    ...prev.paymentData,
                    paymentMethod: {
                      ...prev.paymentData.paymentMethod,
                      holderName: e.target.value
                    }
                  }
                }
                : { ...prev }
            ))}
          />
        </div>
      </div>
    </>
  );
}
