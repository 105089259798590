export const monthsDiff = (from: Date, to: Date) => {
  const months = (to.getFullYear() - from.getFullYear()) * 12 - to.getMonth() + from.getMonth()
  return months <= 1 ? 1 : months
}

export function formatDateTime(dateTime: string | number, hour12 = true) {
  return dateTime
    ? new Date(dateTime).toLocaleString('en-US', {
      dateStyle: 'medium',
      timeStyle: 'short',
      hour12
    })
    : null
}
