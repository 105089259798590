import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { CheckCircleOutline, PersonOutlineOutlined } from '@material-ui/icons';
import React, { useEffect, useMemo } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import useEventCharity from './useEventCharity';
import { useCharityId, useEventId } from './location';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import { formatAsCurrency } from './currency';
import { useSelector } from 'react-redux';
import { Payment } from './Payment';
import { Alert } from '@material-ui/lab';
import ValidatingTextField from './ValidatingTextField';
import { validateEmail, validatePhoneNumber, validateZipCode } from './validate';
import StripePayment from './StripePayment';
import { StripeElementsOptions } from '@stripe/stripe-js';
import useStripeInstance from './useStripeInstace';
import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentProcessor } from './types';

export const CTLIVE_API_URL = process.env.REACT_APP_CTLIVE_API_URL

export interface IAdyenPaymentData {
  paymentMethod: {
    encryptedCardNumber?: string;
    encryptedExpiryMonth?: string;
    encryptedExpiryYear?: string;
    encryptedSecurityCode?: string;
    holderName?: string;
    type: string;
  };
  browserInfo: {
    userAgent: string;
  };
}

export interface IStripePaymentData {
  holderName: string;
  paymentMethodId?: string;
}

const PaymentMethodType = 'card';

export interface IPayload<T extends IAdyenPaymentData | IStripePaymentData> {
  billingAddress: {
    city?: string;
    country?: string;
    postalCode?: string;
    regionId?: string;
    street?: string;
  }
  billingPII: {
    email?: string;
    firstName?: string;
    gender?: string;
    lastName?: string;
    phone?: string;
  }
  donationAmount: number
  donorMessage: string
  donorName: string
  donorType: string | 'self' | 'anon' | 'other'
  paymentData?: T
  taxRate: number
}

const useStyles = makeStyles({
  formControl: {
    alignItems: 'flex-start',
    marginLeft: 0,
    width: '100%',
  },
  formControlLabel: {
    fontSize: 14,
    lineHeight: '16px',
  },
  formControlLabelTop: {
    fontSize: 14,
    lineHeight: '16px',
    marginBottom: 10,
  },
  inputField: {
    marginBottom: 10,
    marginTop: 10
  },
  radioLabel: {
    fontSize: 12,
    lineHeight: '14px',
    textTransform: 'uppercase',
  },
  db: {
    display: 'block !important'
  },
  dn: {
    display: 'none !important'
  },
  fl: {
    float: 'left'
  },
  fr: {
    float: 'right'
  }
})

function DonatePage() {
  const classes = useStyles()
  const eventId = useEventId()
  const charityId = useCharityId()
  const stripe = useStripe()
  const elements = useElements()
  const { charity: donations, currencyId, currencySymbol, event, paymentProcessor } = useEventCharity()
  const history = useHistory()
  const token = useSelector<{ session: { token: string } }, string>(_ => _.session.token)
  const showProcessingFee = donations.feeAllocation === 'athlete';
  const [countries, setCountries] = React.useState([{
    id: 'US',
    name: 'United States'
  }])
  const [regions, setRegions] = React.useState<{
    [key: string]: {
      [key: string]: string
    }
  }>({ US: { 'US-CA': 'California' } })
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [donationsLoading, setDonationsLoading] = React.useState(true)
  const [success, setSuccess] = React.useState(false)
  const [emailId, setEmailId] = React.useState(false)
  const [estimates, setEstimates] = React.useState<{ donation: number, estimate: number }[]>([]);
  const [estimatingTax, setEstimatingTax] = React.useState(false);
  const [avaTaxData, setAvaTaxData] = React.useState({ transactionCode: '', customerCode: '' })
  const [payload, setPayload] = React.useState<IPayload<IAdyenPaymentData | IStripePaymentData>>({
    billingAddress: {
      country: 'US',
    },
    billingPII: {},
    donationAmount: 1.00,
    donorMessage: '',
    donorName: '',
    donorType: '',
    paymentData: undefined,
    taxRate: 0.00,
  })
  console.log('payload', payload)
  const [customDonations, setCustomDonations] = React.useState([{ name: '', value: 0, value_to_show: '0' }])
  const [hasCustomDonations, setHasCustomDonations] = React.useState(false)
  const mapToDonationOption = React.useCallback(({ name, value, value_to_show }) => {
    setHasCustomDonations(true)
    value = parseFloat(value)
    value_to_show = value.toFixed(2)
    return { name, value, value_to_show }
  }, [])

  const getTaxEstimate = React.useCallback(() => {
    const t = estimates.find(_ => _.donation === payload.donationAmount);
    return t ? t.estimate : undefined;
  }, [estimates, payload])
  const submitRef = React.createRef<HTMLInputElement>()
  React.useEffect(() => {
    if (!token) {
      history.replace('/event/' + eventId + '/charity/' + charityId)
      return
    }
    setLoading(true)
    Promise.all([
      fetch('/countries.json')
        .then(_ => _.json())
        .then(json => {
          setCountries(json)
        }),
      fetch('/all-regions.json')
        .then(_ => _.json())
        .then(json => {
          setRegions(json)
        })
    ])
      .finally(() => setLoading(false))
  }, [eventId, charityId, history, setCountries, setRegions, token])

  React.useEffect(() => {
    if (payload.billingAddress.country !== 'US' || !payload.billingAddress.country || !payload.billingAddress.postalCode || payload.billingAddress.postalCode.length < 5) {
      return
    }
    setLoading(true)
    fetch(CTLIVE_API_URL + `/registration/lookup/postalCode`, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        country: payload.billingAddress.country,
        postalCode: payload.billingAddress.postalCode,
      })
    })
      .then(_ => _.json())
      .then(({ city, regionId }) => {
        setPayload(prev => ({
          ...prev,
          billingAddress: {
            ...prev.billingAddress,
            city: city || prev.billingAddress.city,
            regionId: regionId || prev.billingAddress.regionId,
          }
        }))
      })
      .finally(() => setLoading(false))
  }, [payload.billingAddress.country, payload.billingAddress.postalCode, token])

  const processingFeeRate = donations.feeAllocation === 'event' ? 0 : 0.05;
  const processingFee = payload.donationAmount * processingFeeRate
  const taxes = payload.taxRate * processingFee
  
  const isStripePaymentProcessor =  useMemo(() => [PaymentProcessor.STRIPE, PaymentProcessor.STRIPE_EU].includes(paymentProcessor), [paymentProcessor]);
  
  const handleSubmit = async () => {
    const {
      billingAddress,
      billingPII,
      donationAmount,
      donorMessage,
      donorName,
      donorType,
      paymentData,
    } = payload
    if (!paymentData) {
      setError('Please enter valid credit card information to make your donation.')
      return
    }

    const { email, firstName, lastName, phone } = billingPII
    const [houseNumberOrName, street = billingAddress.street] = billingAddress.street!.split(' ')
    setLoading(true)
    try {
      if (isStripePaymentProcessor) {
        const elementsRes = await elements!.submit()
        if (elementsRes?.error) {
          setError(elementsRes.error.message || "t('unexpectedErrorOccurred')")
          return
        }
        const paymentMethodRes = await stripe!.createPaymentMethod({
          type: PaymentMethodType,
          card: elements!.getElement(CardNumberElement)!,
          billing_details: {
            email: email,
            name: (paymentData as IStripePaymentData).holderName,
            address: {
              city: billingAddress.city,
              country: billingAddress.country,
              line1: billingAddress.street,
              postal_code: billingAddress.postalCode,
              state: billingAddress.regionId?.split('-')[1],
            },
          },
        })
        
        if (paymentMethodRes.error) {
          setError(paymentMethodRes.error.message || "t('unexpectedErrorOccurred')")
          return
        }
        (paymentData as IStripePaymentData).paymentMethodId = paymentMethodRes.paymentMethod.id;
      }
      const res = await fetch(CTLIVE_API_URL + '/donations/transaction', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify({
          breakdown: {
            donationAmount,
            processingFee,
            taxes,
          },
          charityId,
          donor: {
            message: donorMessage,
            name: donorName,
            type: donorType
          },
          eventId,
          payment: {
            ...paymentData,
            amount: {
              currency: currencyId,
              value: (donationAmount + processingFee + taxes) * 100
            },
            billingAddress: {
              city: billingAddress.city,
              country: billingAddress.country,
              postalCode: billingAddress.postalCode,
              stateOrProvince: billingAddress.regionId?.split('-')[1],
              houseNumberOrName,
              street
            },
            shopperEmail: email,
            shopperName: {
              firstName,
              lastName
            },
            telephoneNumber: phone
          },
          avalara: avaTaxData
        }),
      })
      console.log('res', res.status, res.statusText)
      if ([200, 400, 500].includes(res.status)) {
        const text = await res.text()
        console.log('result', text)
        try {
          const json = JSON.parse(text)
          if (json.error) {
            setSuccess(false)
            setError(json.error)
          }
          else switch (json.resultCode) {
            case 'succeeded':
            case "Authorised":
              setSuccess(true)
              setEmailId(json.emailId)
              setError('')
              break;
            case 'payment_failed':
            case "Refused":
              setSuccess(false)
              setError(json.refusalReason)
              break;
            case "Pending":
            case "Received":
            default:
              break;
          }
        }
        catch (err) {
          console.error(err)
          setSuccess(false)
          setError('An unexpected error occurred, please try again. If the issue persists, please contact support@chronotrack.com')
        }
      }
    }
    finally {
      setLoading(false)
    }
  }

  const estimateTax = React.useCallback(async () => {
    const { donationAmount } = payload
    const res = await fetch(CTLIVE_API_URL + '/registration/estimateTax', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
      },
      body: JSON.stringify({
        name: `Tax Estimate`,
        email: 'taxestimate@chronotrack.com',
        eventId,
        event,
        billingAddress: {
          street: '111 East Diamond Avenue',
          city: 'Evansville',
          region: 'IN',
          country: 'US',
          postalCode: '47711',
        },
        items: [
          { amount: processingFee, itemCode: 'processing_fee'},
          { amount: donationAmount, itemCode: 'product_donations'}
        ],
        transactionCode: avaTaxData.transactionCode
      }),
    })
    return JSON.parse(await res.text());
  }, [payload, avaTaxData, event, eventId, processingFee, token])

  useEffect(() => {
    if (getTaxEstimate() === undefined) {
      setEstimatingTax(true);
      estimateTax().then(({ tax, transactionCode, customerCode }) => {
        setEstimates((prevEstimates) => prevEstimates.concat({
          donation: payload.donationAmount, estimate: tax
        }))
        setAvaTaxData({ transactionCode, customerCode })
      }).finally(() => {
        setEstimatingTax(false);
      })
    }
  }, [payload.donationAmount]) // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (donations.customDonations) {
      setDonationsLoading(true)
      setCustomDonations(donations.customDonations.map(mapToDonationOption))
    }
    setDonationsLoading(false)
  }, [donations, mapToDonationOption])
  
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography style={{
          fontSize: 24,
          fontWeight: 300,
          lineHeight: '28px',
          marginBottom: 23,
        }}>Donation Payment</Typography>
        <form
          onSubmit={e => {
            e.preventDefault()
            e.stopPropagation()
            // do not await, or form validation is borked
            handleSubmit()
          }}
        >
          <input type="submit" ref={submitRef} style={{ display: 'none' }} />
          <Prompt
            when={!!token && !success}
            message={'Are you sure you want to leave?'}
          />
          <FormControlLabel
            classes={{
              label: classes.formControlLabelTop,
              root: classes.formControl
            }}
            control={
              <RadioGroup
                data-cy='donation_type'
                onChange={e => setPayload(prev => {
                  const donorType = e.target.value
                  return ({
                    ...prev,
                    donorName: donorType === 'anon' ? '' : prev.donorName,
                    donorType,
                  })
                })}
                row
                value={payload.donorType || ''}
              >
                {[{
                  label: 'Myself',
                  value: 'self'
                }, {
                  label: 'Anonymous',
                  value: 'anon'
                }, {
                  label: 'Dedicate To',
                  value: 'other'
                }].map(x => (
                  <FormControlLabel
                    classes={{
                      label: classes.radioLabel,
                    }}
                    control={<Radio required />}
                    data-cy={'donation_type_' + x.value}
                    disabled={success}
                    key={x.value}
                    label={x.label}
                    labelPlacement={'end'}
                    value={x.value}
                  />
                ))}
              </RadioGroup>
            }
            label={'Donation Type'}
            labelPlacement='top'
          />
          <Box marginTop='24px'>
            <TextField
              className={classes.inputField}
              data-cy='donor_name'
              disabled={success || payload.donorType === 'anon'}
              fullWidth
              label='Name'
              onChange={e => setPayload(prev => ({
                ...prev,
                donorName: e.target.value
              }))}
              required={payload.donorType !== 'anon'}
              value={payload.donorName || ''}
              variant='outlined'
            />
            <TextField
              className={classes.inputField}
              data-cy='donor_message'
              disabled={success}
              fullWidth
              label='Personal Message'
              onChange={e => setPayload(prev => ({
                ...prev,
                donorMessage: e.target.value
              }))}
              value={payload.donorMessage || ''}
              variant='outlined'
            />
          </Box>
          <Box marginTop='40px'>
            <FormControlLabel
              classes={{
                label: classes.formControlLabelTop,
                root: classes.formControl
              }}
              control={
                <CurrencyTextField
                  currencySymbol={currencySymbol}
                  decimalCharacter="."
                  disabled={success}
                  digitGroupSeparator=","
                  fullWidth
                  inputProps={{
                    'data-cy': 'amount'
                  }}
                  minimumValue="1.00"
                  onChange={(_: any, donationAmount: number) => setPayload(prev => ({
                    ...prev,
                    donationAmount,
                    processingFee: +(donationAmount * processingFeeRate).toFixed(2),
                  }))}
                  outputFormat="number"
                  placeholder="Amount"
                  variant="outlined"
                  value={payload.donationAmount || 0}
                />
              }
              label='Amount*'
              labelPlacement='top'
            />
          </Box>
          <CircularProgress className={donationsLoading ? classes.db : classes.dn} size='4rem' style={{ color: '#fff', margin: '0 auto', marginTop: '20px' }} />
          {!hasCustomDonations ?
            <Box className={!donationsLoading ? '' : classes.dn} display='flex' flexDirection='row' justifyContent='space-between' marginTop='36px' width='100%'>
              {[25, 50, 100, 200, 500].map(donationAmount => (
                <Button
                  color='primary'
                  data-cy={'amount_' + donationAmount}
                  disabled={success}
                  fullWidth
                  key={donationAmount}
                  onClick={() => setPayload(prev => ({
                    ...prev,
                    donationAmount,
                    processingFee: +(donationAmount * processingFeeRate).toFixed(2),
                  }))}
                  style={{
                    fontSize: 12,
                    fontWeight: 900,
                    height: 45,
                    lineHeight: '14px',
                    maxWidth: 101,
                  }}
                  variant='contained'
                >{currencySymbol}{donationAmount}</Button>
              ))}
            </Box>
            :
            <Box display={!donationsLoading ? 'flex' : 'none'} flexDirection='column' justifyContent='space-between' marginTop='20px' width='100%'>
              {customDonations.map(oneDonation => (
                <Box flexGrow='1' textAlign='center' marginBottom='15px'>
                  <Box className={classes.fl} width='50%' textAlign='right' >
                    <Button
                      color='primary'
                      data-cy={oneDonation ? 'amount_' + oneDonation.value : ''}
                      disabled={success}
                      fullWidth
                      key={oneDonation ? oneDonation.value : ''}
                      onClick={() => setPayload(prev => ({
                        ...prev,
                        donationAmount: oneDonation.value,
                        processingFee: +(oneDonation.value * processingFeeRate).toFixed(2),
                      }))}
                      style={{
                        fontSize: 12,
                        fontWeight: 900,
                        height: 45,
                        lineHeight: '14px',
                        maxWidth: 101,
                      }}
                      variant='contained'
                    >{currencySymbol}{oneDonation.value_to_show}</Button>
                  </Box>
                  <Box className={classes.fr} width='50%' textAlign='left' paddingLeft='10px' paddingTop='10px'>
                    <Typography>{oneDonation.name}</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          }
          <Typography style={{
            fontSize: 14,
            lineHeight: '16px',
            marginBottom: 14,
            marginTop: 42,
          }}>Payment Information</Typography>
          <Box display='flex' flexDirection='row'>
            <TextField
              className={classes.inputField}
              data-cy='first_name'
              disabled={success}
              fullWidth
              label='First Name'
              onChange={e => setPayload(prev => ({
                ...prev,
                billingPII: {
                  ...prev.billingPII,
                  firstName: e.target.value
                }
              }))}
              required
              style={{ marginRight: 10, }}
              value={payload.billingPII.firstName || ''}
              variant='outlined'
            />
            <TextField
              className={classes.inputField}
              data-cy='last_name'
              disabled={success}
              fullWidth
              label='Last Name'
              onChange={e => setPayload(prev => ({
                ...prev,
                billingPII: {
                  ...prev.billingPII,
                  lastName: e.target.value
                }
              }))}
              required
              value={payload.billingPII.lastName || ''}
              variant='outlined'
            />
          </Box>
          <TextField
            className={classes.inputField}
            data-cy='street'
            disabled={success}
            fullWidth
            label='Address'
            onChange={e => setPayload(prev => ({
              ...prev,
              billingAddress: {
                ...prev.billingAddress,
                street: e.target.value,
              }
            }))}
            required
            value={payload.billingAddress.street || ''}
            variant='outlined'
          />
          <TextField
            className={classes.inputField}
            data-cy='city'
            disabled={success}
            fullWidth
            label='City'
            onChange={e => setPayload(prev => ({
              ...prev,
              billingAddress: {
                ...prev.billingAddress,
                city: e.target.value
              }
            }))}
            required
            value={payload.billingAddress.city || ''}
            variant='outlined'
          />
          <Box alignItems='center' display='flex' flexDirection='row'>
            <TextField
              data-cy='country'
              disabled={success}
              fullWidth
              label='Country'
              onChange={e => setPayload(prev => ({
                ...prev,
                billingAddress: {
                  ...prev.billingAddress,
                  country: e.target.value,
                }
              }))}
              required
              select
              style={{ marginRight: 10, }}
              value={payload.billingAddress.country || ''}
              variant='outlined'
            >
              {countries.map(country => (
                <MenuItem
                  data-cy={'country_' + country.id}
                  disabled={success}
                  key={country.id}
                  value={country.id}
                >{country.name}</MenuItem>
              ))}
            </TextField>
            <ValidatingTextField
              className={classes.inputField}
              data-cy='postal_code'
              disabled={success}
              fullWidth
              invalidMessage='Invalid zip code'
              label='Zip'
              onChange={async postalCode => {
                setPayload(prev => ({
                  ...prev,
                  billingAddress: {
                    ...prev.billingAddress,
                    postalCode,
                  }
                }))
              }}
              required
              style={{ marginRight: 10, }}
              validator={validateZipCode}
              value={payload.billingAddress.postalCode || ''}
              variant='outlined'
            />
            <TextField
              data-cy='state_or_province'
              disabled={success}
              fullWidth
              label='State'
              onChange={e => setPayload(prev => ({
                ...prev,
                billingAddress: {
                  ...prev.billingAddress,
                  stateOrProvince: e.target.value
                }
              }))}
              required={(payload.billingAddress.country === 'US' || payload.billingAddress.country === 'CA' || payload.billingAddress.country === 'UK') ? true : false}
              select
              value={payload.billingAddress.regionId || ''}
              variant='outlined'
            >
              {Object.keys(regions[payload.billingAddress.country || 'US'] || {}).map(region => (
                <MenuItem
                  data-cy={'state_or_province_' + region}
                  key={region}
                  value={region}
                >{regions[payload.billingAddress.country!][region]}</MenuItem>
              ))}
            </TextField>
          </Box>
          <Box alignItems='center' display='flex' flexDirection='row'>
            <ValidatingTextField
              className={classes.inputField}
              data-cy='telephone_number'
              disabled={success}
              fullWidth
              invalidMessage='Invalid phone number'
              label='Phone'
              onChange={phone => setPayload(prev => ({
                ...prev,
                billingPII: {
                  ...prev.billingPII,
                  phone
                }
              }))}
              required
              style={{ marginRight: 10, maxWidth: 160 }}
              type='tel'
              validator={validatePhoneNumber}
              value={payload.billingPII.phone || ''}
              variant='outlined'
            />
            <ValidatingTextField
              className={classes.inputField}
              data-cy='email_address'
              disabled={success}
              fullWidth
              invalidMessage='Invalid email'
              label='Email Address'
              onChange={email => setPayload(prev => ({
                ...prev,
                billingPII: {
                  ...prev.billingPII,
                  email
                }
              }))}
              required
              validator={validateEmail}
              value={payload.billingPII.email || ''}
              variant='outlined'
            />
          </Box>
          {success
            ? null
            : <Box marginTop='24px'>
              {isStripePaymentProcessor ? (
                <StripePayment
                  setPayload={setPayload as React.Dispatch<React.SetStateAction<IPayload<IStripePaymentData>>>}
                />
              ) : (
                <Payment
                  setPayload={setPayload as React.Dispatch<React.SetStateAction<IPayload<IAdyenPaymentData>>>}
                />
              )}
            </Box>
          }
        </form>
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography style={{
          fontSize: 24,
          fontWeight: 300,
          lineHeight: '28px',
          marginBottom: 23,
        }}>Summary</Typography>
        <Box alignItems='center' display='flex' flexDirection='row' justifyContent='space-between'>
          <Typography style={{
            fontSize: 14,
            lineHeight: '28px',
          }}>Donor Name: {payload.donorType === 'anon' ? 'Anonymous' : payload.donorName}</Typography>
          <Typography style={{
            fontSize: 14,
            lineHeight: '28px',
          }}>Date: {new Date().toLocaleDateString()}</Typography>
        </Box>
        <Typography style={{
          fontSize: 14,
          lineHeight: '28px',
        }}>Donation To: {donations.name}</Typography>
        <Table style={{ marginTop: 54 }}>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <Typography style={{
                  fontSize: 14,
                  lineHeight: '22px',
                }}>Donation Amount</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography style={{
                  fontSize: 14,
                  lineHeight: '16px',
                }}>
                  {formatAsCurrency(payload.donationAmount, currencyId)}
                </Typography>
              </TableCell>
            </TableRow>
            {showProcessingFee &&
              <TableRow>
                <TableCell component="th" scope="row">
                  <Typography style={{
                    fontSize: 14,
                    lineHeight: '22px',
                  }}>Processing Fee</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography style={{
                    fontSize: 14,
                    lineHeight: '16px',
                  }}>
                    {formatAsCurrency(processingFee, currencyId)}
                  </Typography>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
        <Grid container>
          <Grid item xs={6}>
          </Grid>
          <Grid item xs={6}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography style={{
                      fontSize: 14,
                      lineHeight: '22px',
                    }}>Subtotal</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography style={{
                      fontSize: 14,
                      lineHeight: '16px',
                    }}>
                      {formatAsCurrency(payload.donationAmount + processingFee, currencyId)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography style={{
                      fontSize: 14,
                      lineHeight: '22px',
                    }}>Taxes</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography style={{
                      fontSize: 14,
                      lineHeight: '16px',
                    }}>
                      {estimatingTax ? 'Estimating...' : formatAsCurrency(getTaxEstimate() || 0, currencyId)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    <Typography style={{
                      fontSize: 14,
                      fontWeight: 900,
                      lineHeight: '22px',
                    }}>Total</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography style={{
                      fontSize: 14,
                      fontWeight: 900,
                      lineHeight: '16px',
                    }}>
                      {formatAsCurrency(payload.donationAmount + processingFee + (getTaxEstimate() || 0), currencyId)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Box marginTop='48px' textAlign='center' width='100%'>
            <Button
              color={success ? 'secondary' : 'primary'}
              data-cy='submit_payment'
              disabled={!payload.paymentData}
              fullWidth
              onClick={() => {
                if (loading) return
                if (success) setSuccess(false)
                else submitRef.current?.click()
              }}
              startIcon={success ? <CheckCircleOutline /> : null}
              style={{
                fontSize: 12,
                fontWeight: 900,
                height: 45,
                lineHeight: '14px',
              }}
              variant='contained'
            >{success ? (
              <>
                Thank you for donating!
              </>
            ) : loading
              ? <CircularProgress size='1rem' style={{ color: '#000' }} />
              : (
                <>
                  Donate {formatAsCurrency(payload.donationAmount, currencyId)}
                </>
              )}</Button>
            {success || loading
              ? null
              : <Button
                color='primary'
                fullWidth
                onClick={() => history.goBack()}
                style={{ marginTop: 32 }}
              >Back</Button>
            }
            {!error
              ? null
              : <Alert
                severity="error"
              >
                <Typography>{error}</Typography>
              </Alert>
            }
            {!success
              ? null
              : <Box
                alignItems='center'
                display='flex'
                flexDirection='row'
                justifyContent='center'
                marginTop='10px'
              >
                <Typography style={{
                  fontSize: 12,
                  lineHeight: '24px',
                }}>Your receipt has been emailed to {payload.billingPII.email}</Typography>
                &nbsp;
                <Button
                  color='primary'
                  onClick={async () => {
                    setLoading(true)
                    await fetch(CTLIVE_API_URL + '/email/resend', {
                      method: 'POST',
                      headers: {
                        Authorization: 'Bearer ' + token,
                      },
                      body: JSON.stringify({ emailId })
                    })
                      .finally(() => setLoading(false))
                  }}
                  style={{
                    fontSize: 12,
                    lineHeight: '24px',
                  }}
                >Resend</Button>
              </Box>
            }
            {!success ||
              (+event.regClosingDate < (new Date().getTime() / 1000)) ||
              (+event.regOpeningDate > (new Date().getTime() / 1000))
              ? null
              : <Button
                color='primary'
                onClick={() => {
                  window.open(event.registerUrl, '_blank')
                }}
                startIcon={<PersonOutlineOutlined />}
                style={{
                  fontSize: 12,
                  lineHeight: '14px',
                  marginTop: 18,
                  textTransform: 'uppercase',
                }}
                variant='outlined'
              >Register to Race</Button>
            }
          </Box>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default function DonatePageWrp() {
  const { paymentProcessor } = useEventCharity()
  const stripeInstance = useStripeInstance(paymentProcessor)
  const options: StripeElementsOptions = {
    paymentMethodCreation: 'manual',
    locale: 'en',
    appearance: {
      variables: {
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
      },
    },
  }
  return <Elements stripe={stripeInstance} options={options}> <DonatePage/> </Elements>
}
