import { TextField } from '@material-ui/core'
import React from 'react'

function ValidatingTextField({
  error = '',
  helperText = '',
  invalidMessage = '',
  label = '',
  onChange = (_:string) => { },
  validator = (_:string) => true,
  value = '',
  ...rest
}) {
  const [_error, setError] = React.useState(error)
  React.useEffect(() => {
    setError(error)
  }, [error])
  return (
    <TextField
      error={!!_error}
      helperText={_error || helperText || ''}
      label={label}
      onChange={e => {
        const { value } = e.target
        if (
          (typeof value === 'string' && !value) || validator(value)
        ) {
          setError('')
        }
        else {
          setError(invalidMessage)
        }
        onChange(value)
      }}
      value={value || ''}
      {...rest}
    />
  )
}

export default ValidatingTextField
