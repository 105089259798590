import React from 'react'
import {
  Box,
  Tabs,
  Tab,
  Table,
  Typography,
  TableCell,
  TableBody,
  TableRow,
} from '@material-ui/core'
import './CharityTabs.scoped.css'
import useEventCharity from './useEventCharity'
import { formatAsCurrency } from './currency'
import { Donor } from './types'

function CharityTabs({
  donors
}: {
  donors: Donor[]
}) {
  const { charity } = useEventCharity()
  const [selectedTab, setSelectedTab] = React.useState(donors.length ? 1 : 0)
  return (
    <>
      <Tabs
        centered
        variant="fullWidth"
        indicatorColor="primary"
        onChange={() => {
          setSelectedTab(prev => prev ? 0 : 1)
        }}
        style={{ width: '100%' }}
        value={selectedTab}
      >
        <Tab label="About" style={{
          fontSize: 24,
          fontWeight: 300,
          lineHeight: '28px',
          textTransform: 'initial',
        }} />
        <Tab label="All Donations" style={{
          fontSize: 24,
          fontWeight: 300,
          lineHeight: '28px',
          textTransform: 'initial',
        }}
        />
      </Tabs>
      {selectedTab === 0
        ? <Box marginTop={4}>
          <Typography>{charity.description}</Typography>
        </Box>
        : <Box>
          <Table style={{
            borderCollapse: 'collapse',
            tableLayout: 'fixed'
          }}>
            <TableBody style={{
              display: 'block',
              maxHeight: 480,
              overflow: 'auto',
              width: '100%'
            }}>
              {donors
                .map(x => ({ ...x, datetime: new Date(x.datetime) }))
                .sort((a, b) => b.datetime.getTime() - a.datetime.getTime())
                .map(x => (
                  <TableRow key={x.datetime.getTime()}>
                    <TableCell component="th" scope="row" style={{ width: '100%' }}>
                      <Typography style={{
                        fontSize: 18,
                        fontWeight: 900,
                        lineHeight: '22px',
                      }}>{x.type === 'anon' ? 'Anonymous' : x.name}</Typography>
                      <Typography style={{
                        color: '#00B0FF',
                        fontSize: 14,
                        fontStyle: 'italic',
                        lineHeight: '22px',
                      }}>{x.message || x.datetime.toLocaleDateString()}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography style={{
                        fontSize: 24,
                        fontWeight: 900,
                        lineHeight: '28px',
                      }}>
                        {formatAsCurrency(1 * x.amount, x.currency, true)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      }
    </>
  )
}

export default CharityTabs
