import { makeStyles } from '@material-ui/core';

export const stripeStyles = (darkMode: boolean) => ({
  style: {
    base: {
      color: darkMode ? '#fff' : '#000',
      fontSize: '16px',
      '::placeholder': {
        color: '#a9a9a9',
        fontSize: '1rem',
      },
    },
  },
})

export const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  errorText: {
    margin: '3px 14px 0',
  },
  field: {
    height: '56px',
    padding: '16px 14px',
    width: '100%',
    borderRadius: '4px',
    transition: 'border-color 0.2s',
    border: `1px solid ${theme.palette.type === 'dark' ? '#ffffff33' : '#cfcfcf'}`
  },
  formFieldLabel: {
    fontSize: '14px',
    background: theme.palette.background.paper,
    padding: '5px',
  },
  cardHolderInput: {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    background: 'none',
    fontSize: '16px',
    color: theme.palette.type === 'dark' ? '#fff' : '#000',
    '&::placeholder': {
      color: '#a9a9a9',
      fontSize: '1rem',
    },
  },
}));
